<template>
  <div class="auth-wrapper auth-v1">
    <div class="auth-inner">
      <v-card class="auth-card">
        <!-- logo -->
        <v-card-title class="d-flex align-center justify-center py-7">
          <router-link to="/" class="d-flex align-center">
            <v-img :src="appLogo" max-width="150px" alt="logo" contain class="me-3"></v-img>
          </router-link>
        </v-card-title>

        <!-- title -->
        <v-card-text>
          <p class="text-2xl font-weight-semibold text--primary mb-2">Gestión Estudiantil</p>
          <p class="mb-2">Inicia sesión con tu cuenta</p>
        </v-card-text>

        <v-card-text>
          <v-alert v-show="alertMessage.message" :color="alertMessage.color" dark> {{ alertMessage.message }} </v-alert>
        </v-card-text>

        <!-- login form -->
        <v-card-text>
          <v-form ref="loginForm" @submit.prevent="handleFormSubmit">
            <v-text-field
              v-model="document"
              outlined
              label="Documento"
              placeholder="documento"
              autocomplete="username"
              :error-messages="errorMessages.document"
              :rules="[validators.required]"
              hide-details="auto"
              class="mb-3"
            ></v-text-field>

            <v-text-field
              v-model="password"
              outlined
              :type="isPasswordVisible ? 'text' : 'password'"
              label="Contraseña"
              autocomplete="password"
              :error-messages="errorMessages.password"
              placeholder="Contraseña"
              :append-icon="isPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
              :rules="[validators.required]"
              hide-details="auto"
              @click:append="isPasswordVisible = !isPasswordVisible"
            ></v-text-field>

            <div class="d-flex align-center justify-space-between flex-wrap">
              <v-checkbox hide-details label="Recuérdame" class="me-3 mt-1"> </v-checkbox>

              <!-- forgot link -->
              <router-link :to="{ name: 'auth-forgot-password' }" class="mt-1">
                ¿Has olvidado tu contraseña?
              </router-link>
            </div>

            <v-btn block color="primary" type="submit" class="mt-6" :loading="buttonLoading" :disabled="buttonLoading">
              Ingresar
            </v-btn>
          </v-form>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>

<script>
import axios from '@axios'
import { useRouter } from '@core/utils'
import { required } from '@core/utils/validation'
import { mdiEyeOffOutline, mdiEyeOutline } from '@mdi/js'
import themeConfig from '@themeConfig'
import { getCurrentInstance, ref } from 'vue'
import store from '@/store'

export default {
  setup() {
    // Template Ref
    const loginForm = ref(null)

    const vm = getCurrentInstance().proxy
    const { router } = useRouter()

    const isPasswordVisible = ref(false)

    const document = ref(null)
    const password = ref(null)
    const errorMessages = ref([])
    const buttonLoading = ref(false)
    const alertMessage = ref({})

    if (router.currentRoute.params.resetPassSuccess)
      alertMessage.value = { message: 'Contraseña cambiada con éxito, inicie sesión para continuar.', color: 'success' }

    const handleFormSubmit = () => {
      const isFormValid = loginForm.value.validate()

      alertMessage.value = {}

      if (!isFormValid) return

      /*
        1. Make HTTP request to get accessToken
        2. Store received token in localStorage for future use
        3. Make another HTTP request for getting user information
        4. On successful response of user information redirect to home page

        ? We have use promise chaining to get user data from access token
        ? Promise Chaining: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Using_promises#chaining
      */

      buttonLoading.value = true

      axios
        .post(`/auth/login`, { document: document.value, password: password.value })
        .then(response => {
          if (response.data.resetId) {
            router.push({
              name: 'auth-reset-password',
              params: { id: response.data.resetId, expired: true },
            })
          }

          const { userAbility } = response.data

          // Set user ability
          // ? https://casl.js.org/v5/en/guide/intro#update-rules
          vm.$ability.update(userAbility)

          store.commit('user/setUserData', response.data)
          store.dispatch('user/setUserSocket')

          // On success redirect to home
          router.push('/')
        })
        .catch(error => {
          // TODO: Next Update - Show notification
          buttonLoading.value = false
          errorMessages.value = error.response.data.message
        })
    }

    return {
      handleFormSubmit,
      buttonLoading,
      alertMessage,

      isPasswordVisible,
      document,
      password,
      errorMessages,
      icons: {
        mdiEyeOutline,
        mdiEyeOffOutline,
      },
      validators: {
        required,
      },

      // themeConfig
      appName: themeConfig.app.name,
      appLogo: themeConfig.app.logo,

      // Template Refs
      loginForm,
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@core/preset/preset/pages/auth.scss';
</style>
